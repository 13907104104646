<template>
  <div id="faq-page">
    <!-- JUMBOTRON -->
    <div class="faq-jumbotron">
      <div class="faq-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
        <h1 class="mb-1 text-white">Have Any Questions?</h1>
        <vs-input class="w-full mt-6" icon="icon-search" icon-no-border icon-pack="feather" placeholder="Search"
                  size="large" v-model="faqSearchQuery"/>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg">
        <vx-card>

          <h4>Authors</h4>
          <ul class="faq-supporters mt-4">
            <li :key="supporter.id" class="mt-4" v-for="supporter in supporters">
              <div class="flex items-center">
                <vs-avatar :src="supporter.img" class="mr-3" size="35px"/>
                <div class="leading-tight">
                  <p class="font-semibold">{{ supporter.name }}</p>
                </div>
              </div>
            </li>
          </ul>
        </vx-card>
      </div>

      <!-- FAQ COL -->
      <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
        <vs-collapse accordion class="p-0" type="margin">
          <vs-collapse-item :class="{'mt-0': !index}" :key="que.id" class="faq-bg rounded-lg"
                            v-for="(que,index) in filteredFaq">
            <div slot="header"><h5>{{ que.question }}</h5></div>
            <p>{{ que.ans }}</p>
          </vs-collapse-item>
        </vs-collapse>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      faqSearchQuery: '',
      faqFilter: 1,
      faqs: [],
      supporters: []
    }
  },
  computed: {
    filteredFaq () {
      return this.faqs.filter((faq) => {
        if (this.faqFilter === 1) return faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
          faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase())
        else if (this.faqFilter === 2) return faq.categoryId === 2 &&
          (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
            faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()))
        else if (this.faqFilter === 3) return faq.categoryId === 3 &&
          (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
            faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()))
        else if (this.faqFilter === 4) return faq.categoryId === 4 &&
          (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
            faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()))
        else if (this.faqFilter === 5) return faq.categoryId === 5 &&
          (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
            faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()))
      })
    }
  },
  methods: {
    loadFaqs () {


      this.$http.get('faqs')
        .then(response => {

          if (response.data && response.data.data) {

            const list = response.data.data
            const authors = []

            list.forEach((value, index) => {
              if (!authors.includes(value.author)) {
                authors.push(value.author)
              }
              this.faqs.push({
                id: value.id,
                question: value.question,
                ans: value.answer,
                categoryId: 0
              })
            })

            authors.forEach(id => {

              this.$http.get(`users/${id}`)
                .then(response => {

                  if (response.data.data) {

                    const author = response.data.data
                    this.supporters.push({
                      id: author.id,
                      name: `${author.first_name} ${author.last_name}`,
                      img: `https://portal-cdn.rapid.education/profile-pictures/${author.profile_picture}.jpeg`
                    })

                  }

                })
                .catch(error => {

                })

            })

          } else if (response.data) {
          }

        })
        .catch(error => {

        })
    }
  },
  components: {},
  created () {
    this.loadFaqs()
  }
}
</script>

<style lang="scss">
#faq-page {
  .faq-jumbotron-content {
    background-image: url('https://portal-cdn.rapid.education/images/bgs/colorful-bg.jpg');
    background-size: cover;
  }

  .faq-bg {
    background-color: #fff;
  }
}
</style>
